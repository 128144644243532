<template>
  <section class='overflow-x-scroll py-8'>
    <h4>종목정보</h4>
    <table class='ipo-table'>
      <thead>
        <tr class='border-b'>
          <th>종목코드</th>
          <th>종목명</th>
          <th>증권사</th>
          <th class='text-right'>자본금</th>
          <th class='text-right'>자본총계(자기자본)</th>
          <th class='text-right'>자산총액</th>
          <th>수요예측일</th>
          <th>청약일</th>
          <th>납입일</th>
          <th>상장일</th>
          <th>상장시장</th>
          <th class='text-right'>신주비율</th>
          <th class='text-right'>구주비율</th>
          <th>벤처여부</th>
          <th>이전상장여부</th>
        </tr>
      </thead>
      <tbody>
        <tr class='text-gray-700'>
          <td>{{ share_code }}</td>
          <td>{{ name }}</td>
          <td>
            <select v-model='selectedBrokerId'
              class='form-select'>
              <option v-for='broker in filteredBrokers'
                      :key='`ipos-broker-${broker.id}`'
                      :value='broker.id'>
                {{broker.name}}
              </option>
            </select>
          </td>
          <td>
            <vue-numeric
              v-model='capital'
              output-type='number'
              class='form-input text-right'
              style='width: 10rem'
              separator=',' />
          </td>
          <td>
            <vue-numeric
              v-model='owned_capital'
              output-type='number'
              class='form-input text-right'
              style='width: 10rem'
              separator=',' />
          </td>
          <td>
            <vue-numeric
              v-model='total_assets_amount'
              output-type='number'
              class='form-input text-right'
              style='width: 10rem'
              separator=',' />
          </td>
          <td>
            <select v-model='selectedPredictedDate'
              class='form-select'>
              <option v-for='date in getDatesStartToEnd(predicted_start_date, predicted_end_date)'
                      :key='`predicted-date-${date}`'
                      :value='date'>
                {{date}}
              </option>
            </select>
          </td>
          <td>
            <select v-model='selectedSubscriptionDate'
              class='form-select'>
              <option v-for='date in getDatesStartToEnd(subscription_start_date, subscription_end_date)'
                      :key='`subscription-date-${date}`'
                      :value='date'>
                {{date}}
              </option>
            </select>
          </td>
          <td>{{ payment_date }}</td>
          <td>{{ listing_date }}</td>
          <td>{{ market }}</td>
          <td>
            <input class='form-input text-right w-20'
              type='number'
              v-model='new_shares_ratio'>
          </td>
          <td>
            <input class='form-input text-right w-20'
              type='number'
              v-model='old_shares_ratio'>
          </td>
          <td>
            <select v-model='is_venture' class='form-select' @change='updateSummaries'>
              <option v-for='option in options'
                      :key='option.value'
                      :value='option.value'>
                {{option.text}}
              </option>
            </select>
          </td>
          <td>
            <select v-model='is_listing_transfer' class='form-select' @change='updateSummaries'>
              <option v-for='option in options'
                      :key='option.value'
                      :value='option.value'>
                {{option.text}}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields }            from 'vuex-map-fields'
import dayjs                    from 'dayjs'

export default {
  name: 'IpoDetails',
  data () {
    return {
      options: [
        { text: '여', value: true  },
        { text: '부', value: false },
      ],
    }
  },
  computed: {
    ...mapState('ipos', [
      'selectedIpoId',
      'selectedIpo',
      'ipoSummaries',
    ]),
    ...mapState('brokers', [
      'brokers',
    ]),
    ...mapFields('ipos', [
      'selectedIpo.id',
      'selectedIpo.share_code',
      'selectedIpo.name',
      'selectedIpo.broker_ids',
      'selectedIpo.capital',
      'selectedIpo.owned_capital',
      'selectedIpo.total_assets_amount',
      'selectedIpo.predicted_start_date',
      'selectedIpo.predicted_end_date',
      'selectedIpo.subscription_date',
      'selectedIpo.subscription_start_date',
      'selectedIpo.subscription_end_date',
      'selectedIpo.payment_date',
      'selectedIpo.listing_date',
      'selectedIpo.market',
      'selectedIpo.new_shares_ratio',
      'selectedIpo.old_shares_ratio',
      'selectedIpo.is_venture',
      'selectedIpo.is_listing_transfer',
      'selectedIpo.is_lock_up',
      'selectedBrokerId',
      'selectedPredictedDate',
      'selectedSubscriptionDate'
    ]),

    filteredBrokers () {
      return (this.selectedIpo.broker_ids) ? this.brokers.filter(broker => this.selectedIpo.broker_ids.includes(broker.id)) : []
    }
  },
  methods: {
    ...mapMutations('ipos', [
      'updateIpoSummaries',
    ]),
    ...mapActions('brokers', [
      'getBrokers',
    ]),
    getDatesStartToEnd (startDate, endDate) {
      var result = []
      var curDate = new Date(startDate)
      while(curDate <= new Date(endDate)) {
          result.push(dayjs(curDate).format('YYYY-MM-DD'))
          curDate.setDate(curDate.getDate() + 1)
      }
      return result
    },
    updateSummaries () {
      let updateFields = { is_venture: this.is_venture, is_listing_transfer: this.is_listing_transfer, is_lock_up: this.is_lock_up }
      this.updateIpoSummaries(updateFields)
    }
  }
}
</script>

